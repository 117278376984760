.dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease;
}

.dialogContent {
    background: white;
    padding: 30px;
    border-radius: 16px;
    min-width: 400px;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.3s ease;
}

.dialogHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid #f1f3f5;
}

.dialogHeader h2 {
    margin: 0;
    color: #114E28;
    font-size: 1.8rem;
    font-weight: 600;
}

.closeButton {
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    color: #adb5bd;
    padding: 5px;
    margin: -5px;
    line-height: 1;
    transition: all 0.3s ease;
}

.closeButton:hover {
    color: #114E28;
    transform: rotate(90deg);
}

.dialogBody {
    padding: 10px 0;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { 
        opacity: 0;
        transform: translateY(20px);
    }
    to { 
        opacity: 1;
        transform: translateY(0);
    }
} 