.container{
    max-width: 95vw;

    margin: 0vh auto;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.container h1{
    font-size: 64px;
    margin-bottom: 20px;

    color: #114E28;
}

.container hr{
    margin-bottom: 20px;
    border-top: 2px solid #114E28;
}

.innerContainer{
    display: flex;

    flex-direction: row;
    justify-content: space-evenly;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 20px;
}

.inputContainer{
    display: flex;
    flex-direction: column;
}

.inputContainer input{
    min-width: 350px;
}

.loaderContainer{
    min-width: 100vw;
    min-height: 100vh;

    background-color: rgba(199, 199, 199, 0.825);

    z-index: 50;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

}

@media screen and (max-width: 900px) {

    .container h1{
        font-size: 48px;
    }

    .innerContainer{
        flex-direction: column;

        justify-content: center;

        align-items: center;

    }

    .inputContainer input{
        min-width: 300px;
    }

}

.customFieldsContainer {
    margin: 40px auto;
    padding: 30px;
    max-width: 1000px;
    background: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.customFieldsContainer h3 {
    color: #114E28;
    font-size: 24px;
    text-align: center;
    margin-bottom: 25px;
    position: relative;
    padding-bottom: 15px;
}

.customFieldsContainer h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: #114E28;
    border-radius: 2px;
}

.addFieldButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.customFieldsContainer > button {
    display: block;
    margin: 0 auto 30px;
}

.customFieldRow {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.customFieldRow:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.customFieldRow button {
    height: 40px;
    padding: 0 20px;
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.customFieldRow button:hover {
    background: #c82333;
    transform: translateY(-2px);
}

@media screen and (max-width: 900px) {
    .customFieldsContainer {
        margin: 20px 15px;
        padding: 20px;
    }

    .customFieldRow {
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 15px;
    }

    .customFieldRow button {
        width: 100%;
    }
}