.container {
    padding: 30px;
    background: #ffffff;
    border-radius: 12px;
    max-width: 600px;
    margin: 0 auto;
}

.inputSection {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 40px;
    background: linear-gradient(145deg, #f8f9fa, #ffffff);
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.inputGroup {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
}

.inputGroup input {
    border: 2px solid #e9ecef;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 14px;
    transition: all 0.3s ease;
    width: 100%;
}

.inputGroup input:focus {
    border-color: #114E28;
    box-shadow: 0 0 0 3px rgba(17, 78, 40, 0.1);
}

.inputGroup label {
    font-weight: 500;
    color: #495057;
    margin-bottom: 8px;
}

.inputGroup > div:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.tooltipIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background: #114E28;
    color: white;
    border-radius: 50%;
    font-size: 14px;
    cursor: help;
    transition: all 0.3s ease;
    margin-top: 32px;
}

.tooltipIcon:hover {
    transform: scale(1.1);
    background: #0d3e1f;
}

.selectGroup {
    margin-top: 5px;
}

.preview {
    margin-top: 40px;
}

.preview h3 {
    color: #114E28;
    font-size: 1.4rem;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 2px solid #f1f3f5;
    font-weight: 600;
}

.tagsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 15px;
}

.tagButton {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 14px 18px;
    background: white;
    border: 2px solid #e9ecef;
    border-radius: 10px;
    color: #495057;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.tagButton:hover {
    border-color: #114E28;
    background: #f8f9fa;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(17, 78, 40, 0.1);
}

.tagIcon {
    font-size: 1.3em;
    min-width: 24px;
    text-align: center;
}

.tagLabel {
    flex: 1;
    text-align: left;
    font-size: 0.95em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.copied {
    background: #114E28;
    color: white;
    border-color: #114E28;
}

.copied .tagIcon,
.copied .tagLabel {
    opacity: 0.9;
}

.copyIndicator {
    position: absolute;
    right: 12px;
    background: rgba(255, 255, 255, 0.2);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8em;
    opacity: 0;
    transition: all 0.3s ease;
}

.tagButton:hover .copyIndicator,
.copied .copyIndicator {
    opacity: 1;
}

@media (max-width: 480px) {
    .container {
        padding: 20px;
    }

    .inputSection {
        padding: 20px;
    }

    .tagsContainer {
        grid-template-columns: 1fr;
    }
} 