.container {
    max-width: 1200px;
    margin: 15vh auto 10vh;
    padding: 0 20px;
    position: relative;
    min-height: calc(100vh - 25vh);
}

.container h1 {
    font-size: 2.5rem;
    color: #114E28;
    margin-bottom: 20px;
}

.container hr {
    border: none;
    height: 2px;
    background: linear-gradient(to right, #114E28, transparent);
    margin-bottom: 40px;
}

.mainContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
}

.uploadSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px;
    background: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.uploadSection:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.formSection {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    background: white;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.expedienteSelect {
    background: #f8f9fa;
    padding: 25px;
    border-radius: 12px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.expedienteSelect h3 {
    color: #114E28;
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;
}

.clientsSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 20px 0;
}

.clientSelect {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.clientSelect p {
    margin-bottom: 15px;
    color: #495057;
}

.actionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 2px solid #f1f3f5;
}

.resetButton {
    background: #fff;
    color: #dc3545;
    border: 2px solid #dc3545;
    padding: 10px 20px;
    border-radius: 8px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.resetButton:hover {
    background: #dc3545;
    color: white;
}

.processButton {
    background: #114E28;
    color: white;
    padding: 12px 25px;
    border-radius: 8px;
    transition: all 0.3s ease;
    font-weight: 500;
}

.processButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(17, 78, 40, 0.3);
}

.downloadSection {
    text-align: center;
    padding: 30px;
    background: linear-gradient(145deg, #114E28, #1a6e3a);
    border-radius: 12px;
    margin: 30px 0;
    color: white;
    animation: slideIn 0.3s ease;
}

.downloadSection p {
    font-size: 1.2rem;
    margin-bottom: 15px;
    opacity: 0.9;
}

.downloadSection a {
    display: inline-block;
    margin-top: 10px;
    background: white;
    color: #114E28;
    text-decoration: none;
    font-weight: bold;
    padding: 12px 25px;
    border-radius: 8px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.downloadSection a:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.tagGeneratorButton {
    position: fixed !important;
    bottom: 30px;
    right: 30px;
    background: #114E28;
    color: white;
    padding: 15px 25px;
    border-radius: 30px;
    box-shadow: 0 4px 15px rgba(17, 78, 40, 0.3);
    transition: all 0.3s ease;
    z-index: 100;
}

.tagGeneratorButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(17, 78, 40, 0.4);
}

.loaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.childContainer{
    display: flex;
    flex-direction: row;

    justify-content: space-around;
}

.labelContainer{
    position: sticky;
    position: -webkit-sticky;
    top: 40%;
    left: 0%;
    height: fit-content;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.labelContainer h3, .labelContainer label{
    color: #114E28;
}

.tagsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding-top: 20px;
    gap: 20px;
}

.innerContainer{
    display: flex;

    flex-direction: column;
    justify-content: center;

    align-items: center;

    gap: 20px;
}

.innerContainer p{
    text-align: center;
}

.selectDG{
    min-width: 300px;
    text-align: left;
}

.collapseContainer{
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;
}

.collapseContainer button{
    display: block;
}

.clientContainer{
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;

    text-align: center;

    width: fit-content;
}

.clientContainer button{
    margin-top: 20px;
    min-width: 300px;
}

.clientContainer hr{
    min-width: 400px;
}

.infoContainer{
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 40px;
}

.collapsedButtons{
    display: flex;
    text-align: center;

    justify-content: center;
    align-items: center;

    flex-direction: column;
}

.innerCollapsedButtons{
    display: flex;

    flex-wrap: wrap;

    justify-content:center;
    gap: 20px;
    
}

.sendButton hr{
    width: 800px;
}

.sendButton button{
    display: block;
    min-width: 800px;
}

.sendButton{
    margin-top: 20px;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.downloadContainer{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.downloadContainer a{
    background-color: #114E28;
    text-decoration: none;

    color: #fff;

    font-weight: 800;
    font-size: 18px;

    padding: 20px 30px;

    transition: .2s linear;

    border-radius: 10px;
    border: 2px #114E28 solid;
}

.downloadContainer a:hover{
    background-color: #fff;

    color: #114E28;
}

@media screen and (max-width: 900px) {

    .container h1{
        font-size: 48px;
    }

    .sendButton hr{
        width: calc(100vw - 50px);
    }
    
    .clientContainer hr{
        min-width: calc(100vw - 50px);
    }

    .childContainer{
        flex-wrap: wrap;
    }
    
    .labelContainer{
        position: relative;
        height: fit-content;
        max-width: calc(100vw - 50px);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-bottom: 20px;
    }
}

.selectTag {
    min-width: 300px;
    margin: 15px 0;
}

.tagSection {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.tagSection h4 {
    color: #114E28;
    margin-bottom: 15px;
    font-size: 16px;
}

.tagsContainer {
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
}

.tagButton {
    margin: 5px;
    padding: 8px 16px;
    background: white;
    border: 1px solid #114E28;
    color: #114E28;
    transition: all 0.2s ease;
}

.tagButton:hover {
    background: #114E28;
    color: white;
}

.labelInnerContainer {
    text-align: center;
    margin-bottom: 20px;
}

.labelInnerContainer h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.labelInnerContainer p {
    color: #666;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}