.tooltipTrigger {
    display: inline-flex;
    position: relative;
}

.tooltipContent {
    position: fixed;
    transform: translate(-50%, -100%);
    background: #333;
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 13px;
    white-space: nowrap;
    z-index: 9999;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.2s ease;
}

.arrow {
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translate(-50%, -90%); }
    to { opacity: 1; transform: translate(-50%, -100%); }
} 